import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Image from 'gatsby-image'

const TranscriptionsPage = ({
  data: {
    images: { edges: image_nodes },
    transcriptions: { edges: transcription_nodes },
  },
}) => {
  const images = image_nodes.map((i) => i.node)
  const transcriptions = transcription_nodes
    .map((t) => t.node)
    .map((t) => {
      t.image = images.find((i) => i.name === t.id)
      return t
    })
  return (
    <Layout>
      <div id="pageheader">
        <h1>Transcriptions</h1>
        <span>Take a look</span>
      </div>
      <p>I offer music transcription services.</p>
      <p>
        I will transcribe your original music from a recording (MP3 or Compact
        Disc). This can be a solo instrument, voice and piano, your whole band,
        or any other combination.
      </p>
      <p>
        Pricing is on a per-job basis. My hourly rate is $25/hr which generally
        equates to one page of music. However, every piece varies in complexity
        and difficulty so please contact me for a *Free Quote*.
      </p>
      <p>
        Final product will be provided as a PDF, Sibelius, and/or MIDI file.
      </p>
      <h3>Examples:</h3>
      <div className="row transcriptions">
        {transcriptions.map(
          ({ id, title, image: { name, childImageSharp } }) => (
            <div className="col-12 col-sm-6 col-md-4" key={id}>
              <a href={`/pdfs/${name}.pdf`}>
                <h4>{title}</h4>
                <Image fluid={childImageSharp.fluid} />
              </a>
            </div>
          )
        )}
      </div>
    </Layout>
  )
}
export const query = graphql`
  query TranscriptionsQuery {
    images: allFile(
      filter: { relativeDirectory: { eq: "pdfs" }, ext: { eq: ".png" } }
    ) {
      edges {
        node {
          name
          ext
          childImageSharp {
            fluid(maxWidth: 185) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    transcriptions: allTranscriptionsYaml {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`

export default TranscriptionsPage
